.user-profile-user-info{
 
     
} 
.user-profile-user-info .input-form{
    display: flex;
    flex-direction: column;
align-items: center;
justify-content: center;
   
    margin-top: 3rem;
}
.user-profile-user-info .top-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.user-profile-user-info .top-section .input-div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.user-profile-user-info .top-section .input-div input{
    border-radius: 5px;
    padding: 0.2rem 0;
}

.user-profile-user-info .bottom-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.user-profile-user-info .bottom-section .input-div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
   

}
.user-profile-user-info .bottom-section .input-div input{
    border-radius: 5px;
    padding: 0.2rem 0;
    

} 