.userProfile-myorder{ 
    
}
.userProfile-myorder .myorder .myorder-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    /* background-color: pink; */
    margin-top: 2rem;
    margin-left: 2rem;
    padding: 1rem;
}
.userProfile-myorder .myorder .myorder-card .top-section{
    
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.userProfile-myorder .myorder .myorder-card .bottom-section{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.userProfile-myorder .myorder .myorder-card .input-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
   
    
}
.userProfile-myorder .myorder .myorder-card .input-div p{
    border: 1px solid black;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    
}
.userProfile-myorder .myorder .image-card{
    /* background-color: pink; */
    width: 25%;
    margin-left: 2rem;
    margin-top: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    
}
.userProfile-myorder .myorder .image-card .magazine-name{
    text-align: center;
    padding: 0.5rem;
}
.userProfile-myorder .myorder .image-card .veiw-magazine{
    text-align: center;
    padding: 0.5rem;
}
.userProfile-myorder .myorder .image-card .image{
    width: 100%;

}
.userProfile-myorder .myorder .image-card .image img{
    width: 100%;
    height: 100%;
}