.payment-succesfull{
    /* display: flex;
    align-items: center;
    justify-content: center;
     */
}
.payment-succesfull .image{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
     
}
.payment-succesfull .image h1{
    color: green;
    font-family: "headingbold" !important;
}
.payment-succesfull .image img{
    margin-bottom: 2rem;
    width: 100%;
    height: 200px;
}
.payment-succesfull .check-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

}
.payment-succesfull .check-btn p{
    font-family: "bodyfontbold";
    

}
.payment-succesfull .check-btn svg{
    color: green;
}
.payment-succesfull p{
    font-weight: bolder;
     text-align: center;
      
}
.payment-succesfull .bottom-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;

}
.payment-succesfull .bottom-btn button{
    /* padding: 0.5rem;
    border: none;
    background-color: var(--theme-color);
    color: white;
    border-radius: 5px; */
  
}