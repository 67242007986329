.main-navbar-box {
  background-color: var(--main-color-one);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* margin-bottom: 2rem; */
}

.main-navbar {
  text-transform: uppercase;
}

.main-navbar a {
  font-family: "heading" !important;
  font-weight: 700 !important;
  transition: 0.3s all;
  letter-spacing: 2px;
  color: #3e3d3d !important;
}

.main-navbar a:hover {
  color: var(--theme-color) !important;
  /* text-decoration: underline !important; */
}

.main-navbar .navbar-brand {
  width: 100px;
  margin-right: 10rem;
}

.main-navbar .side-drawer {
  width: 30px;
  margin-right: 0.6rem;
  cursor: pointer;
  /* margin-top: -5px; */
}

.main-navbar .theme-btn {
  padding: 0px 8px !important;
  border: 1px solid var(--theme-color) !important;
  /* color: var(--theme-color) !important; */
  color: var(--white-color) !important;
  border-radius: 20px !important;
  transition: 0.3s;
  text-decoration: none !important;
  margin: 0;
  font-weight: 700 !important;
}
.main-navbar .theme-btn:hover {
  border: 2px solid var(--theme-color) !important;
  /* background: var(--theme-color) !important; */
  color: var(--theme-color) !important;
  background: transparent !important;
}

.profileDropdownColor:hover {
  color: var(--theme-color);
  font-weight: bold;
}

.main-navbar .login-dropdown .ant-space-item {
  cursor: pointer;
  background: var(--main-color-two);

  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

/* .main-navbar .theme-btn:hover, */
.main-navbar .login-dropdown .ant-space-item:hover {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
  text-decoration: none !important;
}

.navbar-offcanvas {
  width: 100% !important;
  /* min-height: 100vh; */
  background: var(--theme-color) !important;
  color: var(--white-color);
}

.navbar-offcanvas .offcanvas-header {
  flex-direction: row-reverse;
  color: var(--white-color);
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-offcanvas a {
  color: var(--white-color) !important;
}

.navbar-offcanvas .offcanvas-header .btn-close {
  margin: 0;
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
  opacity: 1;
  border: none;
  outline: none;
  padding: 0;
}

.navbar-offcanvas .left-links {
  border-left: 2px solid var(--white-color);
  padding: 0 1rem !important;
}

.navbar-offcanvas .left-links a {
  font-size: 2.3rem;
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: bolder;
}

.navbar-offcanvas .mid-links p {
  font-size: 0.9rem;
}

.navbar-offcanvas .mid-links a {
  letter-spacing: 2px;
  font-size: 1.5rem;
  display: inline-block;
  margin-bottom: 5px;
}

.navbar-offcanvas .border-left {
  padding: 0 1rem;
  color: var(--white-color);
  border-left: 1px solid #f3f3f396;
  height: 100%;
}

.navbar-offcanvas .border-left .mid-links p {
  font-size: 1.5rem;
  cursor: pointer;
}
/* .navbar-offcanvas .border-left .mid-links li:hover{
  text-decoration: underline;
  color: var(--theme-color);

} */
.navbar-offcanvas .border-left .mid-links li :hover {
  /* background-color: yellow; */
  text-decoration: underline !important;
  /* color: var(--white-color); */
  /* color: var(--theme-color); */
}
.navbar-offcanvas .left-links li :hover {
  text-decoration: underline !important;
}

.navbar-offcanvas .border-left .mid-links #magazine {
  font-family: headingbold;
}
.navbar-offcanvas .border-left .mid-links #newsletter {
  font-family: headingbold;
}
.navbar-offcanvas .image {
  margin-top: 1rem;
  /* width: 100%; */
  /* height: 100%; */
  /* background: #58040B; */
}

/* .navbar-offcanvas.border-left border-left-image{
  margin-left: -7rem;
} */

.border-left-image {
  margin-left: -8rem;
}
.border-left-image .image {
  width: 75%;

  object-fit: cover;

  object-position: center;
  padding: 0.1rem;
}
/* 
.border-left-image .image img{
  height: %;
} */

/* Fullscreen overlay */
.main-navbar-box .search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85); /* Darker semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

/* Container for search input, close button, and other elements */
.main-navbar-box .search-container {
  position: relative;
  width: 80%;
  max-width: 600px;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* Close button */
.main-navbar-box .close-search-button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

/* Search heading */
.main-navbar-box .search-heading {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Search input styling */
.main-navbar-box .search-input {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border: 2px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.main-navbar-box .search-input:focus {
  border-color: #007bff;
}

/* Footer text with Enter key hint */
.main-navbar-box .search-footer {
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}

.main-navbar-box .key-highlight {
  background-color: #eee;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}
/* Search icon button */
.main-navbar-box .icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
}

/* Full-width search overlay */
.main-navbar-box .search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(255, 255, 255, 0.95);
  overflow: hidden;
  transition: height 0.4s ease;
  z-index: 9;
}

/* Open state for overlay */
.main-navbar-box .search-overlay.open {
  height: 100px; /* Adjust height as needed */
}

/* Search bar container */
.main-navbar-box .search-bar {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Search input */
.main-navbar-box .search-input {
  flex: 1;
  padding: 12px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

/* Close button */
.main-navbar-box .close-button {
  background: none;
  border: none;
  color: #333;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .navbar-offcanvas .left-links a {
    font-size: 1.7rem;
  }
}

@media (max-width: 992px) {
  .main-navbar .container-fluid {
    display: flex;
    justify-content: space-between;
  }
  .border-left-image {
    margin-left: -5rem;
  }

  .main-navbar .navbar-brand {
    margin: 0;
  }

  .main-navbar-box {
    padding: 0;
  }

  .navbar-offcanvas .left-links a {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .navbar-offcanvas .mid-links a {
    font-size: 1.2rem;
  }
  .navbar-offcanvas .mid-links p {
    font-size: 0.8rem;
    margin-bottom: 10px !important;
  }
  .navbar-offcanvas .image {
    height: auto;
  }
  .main-navbar .side-drawer {
    margin-right: 0rem;
  }
}

@media (max-width: 768px) {
  .border-left-image {
    margin-left: -2rem;
  }
}
@media (max-width: 575.98px) {
  .navbar-offcanvas .left-links a {
    font-size: 0.8rem;
  }
  .navbar-offcanvas .mid-links a {
    font-size: 0.8rem;
  }

  .border-left-image {
    margin-left: 0rem;
  }

  .border-left-image p {
    font-size: 0.8rem;
  }
  .navbar-offcanvas .left-links {
    padding-right: 0.2rem;
  }
  .border-left-image .image {
    /* width: 100%; */
    width: 67%;
  }
  .main-navbar .navbar-brand {
    width: 90px;
  }
}
@media (max-width: 450px) {
  .border-left-image .image {
    width: 91%;
  }
}

@media (max-width: 375px) {
  .navbar-offcanvas .border-left-image {
    width: 109%;
  }
}

/* @media (max-width: 992px) {
  .main-navbar .container-fluid {
    display: flex;
    justify-content: space-between;
  }

  .main-navbar .navbar-brand {
    margin: 0;
  }

  .main-navbar-box {
    padding: 0;
  }

  .navbar-offcanvas .left-links a {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .navbar-offcanvas .mid-links a {
    font-size: 1.2rem;
  }
  .navbar-offcanvas .mid-links p {
    font-size: 0.8rem;
    margin-bottom: 10px !important;
  }
  .navbar-offcanvas .border-left-image {
    height: auto;
    margin-bottom: 2rem;
  }
  .navbar-offcanvas .image {
    height: auto;
  }
} */
