.magazine-deg-sec-wives-section {
    background: var(--main-color-one);
    margin-bottom: -6rem;
    padding-bottom: 6rem !important;
}
.know-more{
    border: 2px solid #fff !important;
    color: #fff !important;
}

.magazine-deg-sec-wives-section .top-section .image-section {
    position: relative;
    gap: 0.8rem;
    display: flex;
}

.magazine-deg-sec-wives-section .top-section .main-heading {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -1.8rem;
    /* background: var(--main-color-one); */
    padding: 10px;
    border-radius: 0 0 20px 20px;
    width: 80%;
    text-align: center;
    /* z-index: 1; */
}

.magazine-deg-sec-wives-section .top-section .main-heading h2 {
    font-size: 3rem;
    font-family: "headingbold" !important;
}

.magazine-deg-sec-wives-section .top-section .container-Wrapper{
    display: flex;
    justify-content: space-around;
    margin: 0.5rem;
    padding: 1rem;
    gap: 1.5rem;
    /* justify-content: center;
    align-items: center; */

}
.magazine-deg-sec-wives-section .top-section .image {
    width: 65%;
    /* margin-bottom: 0.5rem; */
    margin-top: 2.5rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
      

}

.magazine-deg-sec-wives-section .top-section .image img {
    width: 100%;
    height: 65%;
    aspect-ratio: 1/1;
    /* object-fit: cover; */
    border-radius: 20px;
    /* margin-top: 1rem; */
}
.magazine-deg-sec-wives-section .top-section .image p{
    /* margin-left: 3rem; */
    margin-top: 0.2rem;
    color: var(--white-color);
    font-size: 1.5rem;
}
 

.magazine-deg-sec-wives-section .top-section .image h5 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-family: "bodyfontbold" !important;
}



.magazine-deg-sec-wives-section .top-section p {
    font-weight: bolder;
}

.magazine-deg-sec-wives-section .bottom-section {
    /* margin-top: 2rem; */
    position: relative;
    /* height: 77vh; */
    height: 82%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-top: -11rem;
    background-color: var(--theme-color);
    

}

.magazine-deg-sec-wives-section .bottom-section .content {
    /* position: absolute; */
    position: relative;
    left: 0;
    top:12rem;
    /* bottom: 0; */
    padding: 2rem;
    /* background: var(--theme-color-gradiant); */
    color: var(--white-color);
}
.magazine-deg-sec-wives-section .bottom-section .content p{
    font-size: 1.4rem;
}


.magazine-deg-sec-wives-section .bottom-section .content h2 {
    font-family: "headingbold" !important;
    text-transform: capitalize;
}



.magazine-deg-sec-wives-section .bottom-section .btn {
    padding: 8px 30px;
    border: 2px solid var(--white-color);
    color: var(--white-color) !important;
    background: transparent !important;
    border-radius: 40px !important;
    font-size: 1.2rem;
    transition: .3s all;
    font-weight: bolder;
    display: inline-block;
    width: fit-content;
    margin-top: 3rem;
  }
  
  .theme-btn:hover {
    background: var(--theme-color) !important;
    color: var(--white-color) !important;
  }


  







.magazine-deg-sec-wives-section .personal-card .content p {
    /* display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; */
    
}

.magazine-deg-sec-wives-section .bottom-section img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.magazine-deg-sec-wives-section .top-heading {
    text-align: center;
    margin-bottom: 2rem;
}

.magazine-deg-sec-wives-section .top-heading h4 {
    margin-top: -0.5rem;
    text-transform: uppercase;
    font-family: "bodyfontbold" !important;
}
.magazine-deg-sec-wives-section .top-heading p{
    font-size: 1.1rem;
}

.magazine-deg-sec-wives-section .personal-card {
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;
}

.magazine-deg-sec-wives-section .personal-card:last-child {
    margin-bottom: 0;
}

.magazine-deg-sec-wives-section .personal-card .image {
    width: 60%;
}

.magazine-deg-sec-wives-section .personal-card .image img {
    width: 100%;
    /* border-radius: 20px; */
    height: 155px;
    /* object-fit: cover; */
    /* object-fit: contain; */
}

.magazine-deg-sec-wives-section .personal-card .content {
    width: 70%;
}

.magazine-deg-sec-wives-section .personal-card .content h4 {
    text-transform: uppercase;
    font-family: "bodyfontbold" !important;
}

.magazine-deg-sec-wives-section .theme-btn {
    /* padding: 8px 30px;
    background: var(--black-color);
    color: var(--white-color) !important;
    border-radius: 40px;
    text-transform: capitalize;
    font-weight: bold;
    display: inline-block; */
    margin-top: 4rem;
     
}
@media(max-width:1800px){  
.magazine-deg-sec-wives-section .bottom-section {
    height: 77%;
    margin-top: -11vw;
}
}
@media(max-width:1700px){
    .magazine-deg-sec-wives-section .bottom-section {
        height: 78%;
}
 }
 @media(max-width:1600px){
    .magazine-deg-sec-wives-section .bottom-section {
        height: 79.5%;
}
 }

 @media(max-width:1500px){
    .magazine-deg-sec-wives-section .bottom-section {
        height: 80%;
}
 }
 @media(max-width:1400px){
    .magazine-deg-sec-wives-section .bottom-section {
        height: 81%;
}
.magazine-deg-sec-wives-section .bottom-section .content {
    top: 10rem;
}
 }

@media (max-width:1300px){
    .magazine-deg-sec-wives-section .personal-card .content p {
        font-size: 1.1vw;
    }
}
@media(max-width:1100px){
    .magazine-deg-sec-wives-section .bottom-section .content {
        top: 9rem;
    }
    .magazine-deg-sec-wives-section .theme-btn {
        margin-top: 3rem;
    }
}

@media(max-width:1200px){
    .magazine-deg-sec-wives-section .top-section .main-heading {
        width: 100%;
    }
    .magazine-deg-sec-wives-section .bottom-section {
        height: 83%;
}
}

@media (max-width:991.98px) {
    .magazine-deg-sec-wives-section .top-section .main-heading h2 {
        font-size: 2rem;
    }

    .magazine-deg-sec-wives-section .top-heading {
        margin: 4rem 0;
    }

    .magazine-deg-sec-wives-section .top-heading .w-75 {
        width: 100% !important;
    }
    .magazine-deg-sec-wives-section{
        margin-bottom: -8rem;
        padding-bottom: 5rem !important;
    }
    .magazine-deg-sec-wives-section .bottom-section {
        height: 75vh;
        margin-top: -25vw;
    }
    .magazine-deg-sec-wives-section .theme-btn {
    margin-top: 3rem;
    }
    .magazine-deg-sec-wives-section .bottom-section .content {
    top: 12rem;
    }
    .magazine-deg-sec-wives-section .personal-card .content p {
        font-size: 1.1rem;
    }

}
@media(max-width:768px){
    .magazine-deg-sec-wives-section .theme-btn {
        margin-top: 2rem;
    }
    .magazine-deg-sec-wives-section .bottom-section {
        height: 78vh;
    }
}
@media(max-width:576px){
    .magazine-deg-sec-wives-section .bottom-section .content {
        top: 8rem;
    }

.magazine-deg-sec-wives-section .bottom-section .content h2 {
font-size: 1.3rem;
}
.magazine-deg-sec-wives-section .bottom-section .content p {
    font-size: 1rem;
}
.magazine-deg-sec-wives-section .bottom-section {
    height: 63vh;
    margin-top: -28vw;
}
.magazine-deg-sec-wives-section .personal-card .content p {
    font-size: 0.8rem;
}
}

@media(max-width:450px){
    .magazine-deg-sec-wives-section .bottom-section .content {
        top: 7rem;
    }
    .magazine-deg-sec-wives-section .bottom-section {
        margin-top: -28vw;
    }
    
.magazine-deg-sec-wives-section .personal-card {
    flex-direction: column;
}
.magazine-deg-sec-wives-section .personal-card .image {
    width: 100%;
}
.magazine-deg-sec-wives-section .personal-card .content {
    width: 100%;
}
.magazine-deg-sec-wives-section .top-heading {
    margin-bottom: 2rem;
}
.magazine-deg-sec-wives-section .personal-card .content p {
    font-size: 1rem;
}

.magazine-deg-sec-wives-section .personal-card .image img {
    height: auto;
}
}
@media(max-width:375px){
    .magazine-deg-sec-wives-section .top-section .image p {
        font-size: 1.2rem;
    }
    .magazine-deg-sec-wives-section .bottom-section .content {
        top: 5rem;
    }
    .magazine-deg-sec-wives-section .theme-btn {
        margin-top: 2rem;
    }

}

