.userProfile-purchase{
/* background-color: pink; */
margin-top: 1rem;
display: flex;
justify-content: space-evenly;
margin-left: auto;
margin-right: auto;

}
.userProfile-purchase .purchase-card{
    /* margin-top:rem; */
    padding: 2rem;
    border: 1px solid black;
    /* background-color: whitesmoke; */
    border-radius: 5px;
    width: 80vw;
   
  
}
.userProfile-purchase .purchase-card .purchase-details{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
 
}

.userProfile-purchase .purchase-card .purchase-details .top-section{
    display: flex;
    gap: 0.5rem;
}
.userProfile-purchase .purchase-card .magazine-card{
    margin-top: 2rem;
     border-radius: 5px;
      border: 1px solid black;
      width: 15%;
      /* display: flex;
      flex-direction: column; */

    
    
}
.userProfile-purchase .purchase-card .magazine-card .veiw-magazine{
  text-align: center;
  padding: 0.5rem;
  color: var(--theme-color);
}
.userProfile-purchase .purchase-card .magazine-card .two{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    font-size: 4rem;
    text-align: center;
    margin-top: 3.5rem;
}
.userProfile-purchase .purchase-card .magazine-card .magazine-name{
    /* display: flex; */
/* justify-content: space-between; */
padding: 0.5rem;
text-align: center;
 

}
.userProfile-purchase .purchase-card .magazine-card .image{
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.userProfile-purchase .purchase-card .magazine-card .image img{
    width: 100%;
     
}
