.editions-about-section img {
    border-radius: 20px;
    /* width: 75%; */
    width: 100%;
    height: 500px;
     /* aspect-ratio: 1/0.3; */
}
.editions-about-section h2{
    /* font-size: 3rem; */
    font-size: 2rem;
    margin-bottom: 1.2rem;
}
.editions-about-section p{
    /* font-size: 1.3rem; */
    font-size: 1.2rem;
}
/* 
@media(max-width:1200px){
    .editions-about-section h2{
        font-size: 2.8rem;
    }
} */


@media (max-width:992px) {

    .editions-about-section{
        /* margin-top: 4rem; */
    }
    .editions-about-section h2{
        /* font-size: 1.5rem; */
        /* font-size: 2rem; */

    }
}
@media(max-width:767.98px){
    
.editions-about-section .bottom-img{
    display: flex;
    align-items: center;
     justify-content: center;
     margin-top: 0rem !important;
}
.editions-about-section p{
    font-size: 1.1rem;
}
.editions-about-section img {
    /* margin-bottom: 4rem; */
    /* width: 50%; */
    
}
 
}



@media(max-width:576px){
    .editions-about-section .text-end{
        text-align: center !important;
    }

    .editions-about-section img {
        /* width: 45%;       */
    }
     
    .editions-about-section h2 {
        font-size: 1.3rem;
    }
    .editions-about-section p {
        font-size: 1rem;
    }
    .editions-about-section img {
        /* width: 70%; */
        height: 250px;
    }
     
}
@media(max-width:450px){
    /* .editions-about-section h2 {
        font-size: 1.4rem;
    } */
    .editions-about-section img {
        /* width: 45%;       */
    }
     
}
