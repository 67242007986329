.user-profile-sidebar{
    border-right: 1px solid black;
    height: 100vh;
    /* height: 100%; */
    /* width: 20%; */
    display: flex;
    flex-direction: column;
    
}
.user-profile-sidebar .sidebar{
    width: 100%;
}
.user-profile-sidebar .sidebar .top-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;
    margin-top: 2rem;
}
.user-profile-sidebar .sidebar .top-section .image{
width: 4vw;
 

    
}
.user-profile-sidebar .sidebar .top-section .image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.user-profile-sidebar .sidebar span{
    /* margin: 2rem; */
     /* padding: 2rem; */

}
.user-profile-sidebar .sidebar .content{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 0.5rem;
     
    gap: 1rem;

}
.user-profile-sidebar .sidebar .content span{
    font-size: 1.1rem;
}
.user-profile-sidebar .logout{
padding: 1rem;
position: fixed;
bottom: 0;
margin-bottom: 2rem;
}