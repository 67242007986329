.userProfile-userProfileHeader{
    display: flex;
    align-items: center;
    justify-content: center;
     
}
.userProfile-userProfileHeader .profile{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 3rem;
    margin-top: 4rem;
    padding-left: 10rem;
    /* margin-left: 10rem; */
}
.userProfile-userProfileHeader .profile .image{
    width:10vw;
    display: flex;
    
    

}
.userProfile-userProfileHeader .profile .image .edit{
    display: flex;
    align-items: center;
    justify-content: center;
  
    
}
.userProfile-userProfileHeader .profile .image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}